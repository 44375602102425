import styled from 'styled-components'

interface ILoadingIcon {
  display?: string
  size?: string
  border?: string
}

function LoadingIcon({display = 'true', size = '64px', border = '8px'}: ILoadingIcon) {
  return (
    <LoadingText display={display} size={size} border={border}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </LoadingText>
  )
}

export default LoadingIcon

const LoadingText = styled.div<{display: string; size?: string; border?: string}>`
  position: absolute;
  top: 50%;
  left: 50%;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  transform: translate(-50%, -50%);
  background-color: #00000005;
  display: ${(props) => props.display};

  & > div {
    position: absolute;
    box-sizing: border-box;
    display: block;
    top: 0;
    left: 0;
    width: ${(props) => props.size};
    height: ${(props) => props.size};
    border: ${(props) => props.border} solid #000;
    border-radius: 50%;
    animation: loading-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #000 transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes loading-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
