import loadable from '@loadable/component'
import LoadingIcon from '../components/LoadingIcon'

const fallback = {
  fallback: <LoadingIcon />
}

const PrivateRoute = loadable(() => import('./accessRoute/PrivateRoute'))
const MypageLayout = loadable(() => import('../layouts/MypageLayout'))
const MypageDetailLayout = loadable(() => import('../layouts/MypageDetailLayout'))
const Orders = loadable(() => import('../pages/mypage/order'), fallback)
const Notice = loadable(() => import('../pages/mypage/notice'), fallback)
const NoticeDetail = loadable(() => import('../pages/mypage/notice/detail'), fallback)
const EditProfile = loadable(() => import('../pages/mypage/editProfile'), fallback)
const UserSupport = loadable(() => import('../pages/mypage/userSupport'), fallback)
const UserSupportDetail = loadable(() => import('../pages/mypage/userSupport/detail'), fallback)
const Error404 = loadable(() => import('../pages/errors/Error404'), fallback)

export default {
  path: '/mypage',
  element: <PrivateRoute />,
  children: [
    {
      element: <MypageLayout />,
      children: [
        {path: 'order', element: <Orders />},
        {path: 'notice', element: <Notice />},
        {path: 'editProfile', element: <EditProfile />},
        {path: 'userSupport', element: <UserSupport />}
      ]
    },
    {
      element: <MypageDetailLayout />,
      children: [
        {path: 'notice/detail/:id', element: <NoticeDetail />},
        {path: 'userSupport/detail/:id', element: <UserSupportDetail />}
      ]
    }
  ],
  errorElement: <Error404 />
}
