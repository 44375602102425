import loadable from '@loadable/component'
import {RecoilRoot} from 'recoil'
import LoadingIcon from '../components/LoadingIcon'

const fallback = {
  fallback: <LoadingIcon />
}

const PublicRoute = loadable(() => import('./accessRoute/PublicRoute'))
const AuthLayout = loadable(() => import('../layouts/AuthLayout'))
const Login = loadable(() => import('../pages/auth/login'), fallback)
const Register = loadable(() => import('../pages/auth/register'), fallback)
const FindPassword = loadable(() => import('../pages/auth/findPassword'), fallback)
const ResetPassword = loadable(() => import('../pages/auth/resetPassword'), fallback)
const Error404 = loadable(() => import('../pages/errors/Error404'), fallback)

export default {
  path: '',
  element: <PublicRoute />,
  children: [
    {
      element: <AuthLayout />,
      children: [
        {path: '/login', element: <Login />},
        {
          path: '/register',
          element: (
            <RecoilRoot>
              <Register />
            </RecoilRoot>
          )
        },
        {
          path: '/findPassword',
          element: (
            <RecoilRoot>
              <FindPassword />
            </RecoilRoot>
          )
        },
        {path: '/resetPassword', element: <ResetPassword />}
      ]
    }
  ],
  errorElement: <Error404 />
}
