import React, {useState, useMemo} from 'react'
import {createPortal} from 'react-dom'

import {ToastContext} from './ToastContext'
import Toast from './Toast'
import styled from 'styled-components'

// Create a random ID
function getId() {
  let first: any = (Math.random() * 46656) | 0
  let second: any = (Math.random() * 46656) | 0
  first = ('000' + first.toString(36)).slice(-3)
  second = ('000' + second.toString(36)).slice(-3)

  return first + second
}

// const toast = useToast()
// toast.open('success', '성공')
// toast.open('fail', '실패')

function ToastProvider(props: any) {
  const [toasts, setToasts] = useState<any>([])
  const success = (content: string) => setToasts((prev: any) => [...prev, {id: getId(), status: 'success', content}])
  const error = (content: string) => setToasts((prev: any) => [...prev, {id: getId(), status: 'error', content}])
  const close = (id: any) => setToasts((prev: any) => prev.filter((toast: any) => toast.id !== id))
  const contextValue = useMemo(() => ({success, error}), [])

  return (
    <ToastContext.Provider value={contextValue}>
      {props.children}

      {createPortal(
        <ToastWrapper>
          {toasts.map((toast: any) => (
            <Toast key={toast.id} status={toast.status} close={() => close(toast.id)}>
              {toast.content}
            </Toast>
          ))}
        </ToastWrapper>,
        document.body
      )}
    </ToastContext.Provider>
  )
}

export default ToastProvider

const ToastWrapper = styled.div`
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 9999;
`
