import React from 'react'
import styled from 'styled-components'
import useProgress from './useProgress'
import useTimeout from './useTimeout'

function Toast(props: any) {
  const time = 1500

  useTimeout(props.close, time)

  return (
    <Wrapper onClick={props.close} className={props.status}>
      <span>{props.children}</span>
    </Wrapper>
  )
}

export default Toast

const Wrapper = styled.ul`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 480px;
  min-width: 200px;
  margin: 0;
  padding: 10px 20px;
  color: #000000;
  font-weight: 500;
  font-size: 16px;
  background-color: #fafafa;
  border-radius: 4px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;

  &::before {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    color: #fff;
    border-radius: 50%;
  }

  &.success {
    &::before {
      content: 'v';
      background-color: #57d747;
    }
  }
  &.error {
    &::before {
      content: 'x';
      background-color: #e23838;
    }
  }
`
