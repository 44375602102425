import loadable from '@loadable/component'
import {RecoilRoot} from 'recoil'
import LoadingIcon from '../components/LoadingIcon'

const fallback = {
  fallback: <LoadingIcon />
}

const PrivateRoute = loadable(() => import('./accessRoute/PrivateRoute'))
const BasicLayout = loadable(() => import('../layouts/BasicLayout'))
const Index = loadable(() => import('../pages'), fallback)
const Policy = loadable(() => import('../pages/contract/policy'), fallback)
const Terms = loadable(() => import('../pages/contract/terms'), fallback)
const Application = loadable(() => import('../pages/application'), fallback)
const Order = loadable(() => import('../pages/rating/order'), fallback)
const OrderDetail = loadable(() => import('../pages/rating/detail'), fallback)
const Faq = loadable(() => import('../pages/faq'), fallback)
const FaqCreate = loadable(() => import('../pages/faq/faqCreate'), fallback)
const Error404 = loadable(() => import('../pages/errors/Error404'), fallback)

export default {
  path: '/',
  element: <PrivateRoute />,
  children: [
    {
      element: <BasicLayout />,
      children: [
        {index: true, element: <Index />},
        {path: 'policy', element: <Policy />},
        {path: 'terms', element: <Terms />},
        {
          path: 'application',
          element: (
            <RecoilRoot>
              <Application />
            </RecoilRoot>
          )
        },
        {
          path: 'rating',
          children: [
            {path: 'order', element: <Order />},
            {path: 'order/detail/:id', element: <OrderDetail />}
          ]
        },
        {
          path: 'faq',
          children: [
            {index: true, element: <Faq />},
            {path: 'create', element: <FaqCreate />}
          ]
        }
      ]
    }
  ],
  errorElement: <Error404 />
}
