import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import {QueryClient, QueryClientProvider} from 'react-query'
import {RouterProvider} from 'react-router'
import {breakPointTheme} from './styles/theme'
import {ThemeProvider} from 'styled-components'
import {ReactQueryDevtools} from 'react-query/devtools'

import './styles/reset.css'
import './styles/index.css'

import routes from './routes/routes'
import {ToastProvider} from './components/elements/toast'

if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const queryClient = new QueryClient()

root.render(
  <React.StrictMode>
    <ThemeProvider theme={breakPointTheme}>
      <QueryClientProvider client={queryClient}>
        <ToastProvider>
          <RouterProvider router={routes} />
          {/* <ReactQueryDevtools /> */}
        </ToastProvider>
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
